exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-feedback-jsx": () => import("./../../../src/pages/feedback.jsx" /* webpackChunkName: "component---src-pages-feedback-jsx" */),
  "component---src-pages-glossary-jsx": () => import("./../../../src/pages/glossary.jsx" /* webpackChunkName: "component---src-pages-glossary-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-article-index-jsx": () => import("./../../../src/templates/article/index.jsx" /* webpackChunkName: "component---src-templates-article-index-jsx" */),
  "component---src-templates-blog-categories-index-jsx": () => import("./../../../src/templates/blogCategories/index.jsx" /* webpackChunkName: "component---src-templates-blog-categories-index-jsx" */),
  "component---src-templates-glossary-index-jsx": () => import("./../../../src/templates/glossary/index.jsx" /* webpackChunkName: "component---src-templates-glossary-index-jsx" */),
  "component---src-templates-local-index-jsx": () => import("./../../../src/templates/local/index.jsx" /* webpackChunkName: "component---src-templates-local-index-jsx" */),
  "component---src-templates-service-index-jsx": () => import("./../../../src/templates/service/index.jsx" /* webpackChunkName: "component---src-templates-service-index-jsx" */),
  "component---src-templates-starlink-index-jsx": () => import("./../../../src/templates/starlink/index.jsx" /* webpackChunkName: "component---src-templates-starlink-index-jsx" */),
  "component---src-templates-sub-service-index-jsx": () => import("./../../../src/templates/subService/index.jsx" /* webpackChunkName: "component---src-templates-sub-service-index-jsx" */)
}

